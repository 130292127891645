import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import Trend from "vuetrend";
Vue.use(Trend);

import { Form, HasError, AlertError } from "vform";
window.Form = Form;

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "3px",
});

import VueAnalytics from 'vue-analytics';
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'G-BZHZZ746QZ',
  router
});

// Mixpanel tracker pakai email blocksafu
import VueMixpanelBrowser from "vue-mixpanel-browser";
Vue.use(VueMixpanelBrowser, {
  token: "35ea54d69875b965177fde910c51c239",
  config: {
    debug: true,
  },
});

// Vue apex chart for use chart in dapps detail
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

// Vue tooltip for tooltip in network logo
import VTooltip from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
Vue.use(VTooltip);

// for truncate address
Vue.filter("truncate", function (text, length, suffix, length2) {
  if (text.length > length) {
    return text.substring(0, length) + suffix + text.substring(32, length2);
  } else {
    return text;
  }
});

Vue.filter("toUSD", function (value) {
  return `$${value.toLocaleString()}`;
});

Vue.filter("toUSDSimple", function (value) {
  return `$${value.toLocaleString("en-US", { maximumFractionDigits: 0 })}`;
});

Vue.filter("toTwoDigits", function (value) {
  return `${value.toFixed(2)}`;
});

Vue.filter("formatNumber", (n) => {
  if (n < 1e3) return Number(n).toFixed(2);
  if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + "K";
  if (n >= 1e5 && n <= 1e6) return +(n / 1e3).toFixed(2) + "K";
  if (n >= 1e6 && n <= 1e9) return +(n / 1e6).toFixed(2) + "M";
  if (n >= 1e9 && n <= 1e12) return +(n / 1e9).toFixed(2) + "B";
});

Vue.filter("truncate", (text, length, suffix, length2) => {
  if (text.length > length) {
    return text.substring(0, length) + suffix + text.substring(39, length2);
  } else {
    return text;
  }
});

Vue.filter("truncateFirst", (text, length, suffix) => {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

// vue social sharing for sharing artikel and dapps to social media
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
