import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/airdrop",
    name: "Airdrop",
    // route level code-splitting
    // this generates a separate chunk (airdrop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "airdrop" */ "../views/Airdrop.vue"),
  },
  {
    path: "/search",
    name: "Search",
    // route level code-splitting
    // this generates a separate chunk (Search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Search" */ "../views/Search.vue"),
  },
  {
    path: "/add-dapps",
    name: "AddDapps",
    // route level code-splitting
    // this generates a separate chunk (Search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AddDapps" */ "../views/AddDapps.vue"),
  },
  {
    path: "/advertise",
    name: "CreateAds",
    // route level code-splitting
    // this generates a separate chunk (Search.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CreateAds" */ "../views/CreateAds.vue"),
  },
  {
    path: "/dapps-detail/:slug",
    name: "DappsDetail",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "DappsDetail" */ "../views/DappsDetail.vue"),
  },
  {
    path: "/shortcut",
    name: "Shortcut",
    // route level code-splitting
    // this generates a separate chunk (Shortcut.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Shortcut" */ "../views/Shortcut.vue"),
  },
  {
    path: "/category-choose/:id",
    name: "CategoryChoose",
    // route level code-splitting
    // this generates a separate chunk (CategoryChoose.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "CategoryChoose" */ "../views/CategoryChoose.vue"
      ),
  },
  {
    path: "/category",
    name: "Category",
    // route level code-splitting
    // this generates a separate chunk (Category.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Category" */ "../views/Category.vue"),
  },
  {
    path: "/ads-detail/:id",
    name: "AdsDetail",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AdsDetail" */ "../views/AdsDetail.vue"),
  },
  {
    path: "/article-detail/:id",
    name: "ArticleDetail",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ArticleDetail" */ "../views/ArticleDetail.vue"),
  },
  {
    path: "/how-to-submit",
    name: "HowToSubmit",
    // route level code-splitting
    // this generates a separate chunk (HowToSubmit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "HowToSubmit" */ "../views/HowToSubmit.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (HowToSubmit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Blog" */ "../views/Blog.vue"),
  },
  {
    path: "/token",
    name: "Token",
    // route level code-splitting
    // this generates a separate chunk (HowToSubmit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Token" */ "../views/Token.vue"),
  },
  {
    path: "/token-details/:id",
    name: "TokenDetail",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TokenDetail" */ "../views/TokenDetail.vue"),
  },
  {
    path: "/nft",
    name: "Nft",
    // route level code-splitting
    // this generates a separate chunk (HowToSubmit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Nft" */ "../views/Nft.vue"),
  },
  {
    path: "/nft-details/:address/:id",
    name: "NftDetails",
    // route level code-splitting
    // this generates a separate chunk (HowToSubmit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "NftDetails" */ "../views/NftDetails.vue"),
  },
  {
    path: "/nft-marketplace-list",
    name: "NftMarketplaceList",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "NftMarketplaceList" */ "../views/NftMarketplaceList.vue"
      ),
  },
  {
    path: "/nft-marketplace/:protocol/:address",
    name: "NftMarketplace",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "NftMarketplace" */ "../views/NftMarketplace.vue"
      ),
  },
  {
    path: "/nft-collection-list",
    name: "NftCollectionList",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "NftCollectionList" */ "../views/NftCollectionList.vue"
      ),
  },
  {
    path: "/nft-collection/:category/:protocol/:slug/:address",
    name: "NftCollection",
    // route level code-splitting
    // this generates a separate chunk (HowToSubmit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "NftCollection" */ "../views/NftCollection.vue"
      ),
  },
  {
    path: "/nft-sales-list",
    name: "NftSalesList",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "NftSalesList" */ "../views/NftSalesList.vue"
      ),
  },
  {
    path: "/nft-fractional-list",
    name: "NftFractionalList",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "NftFractionalList" */ "../views/NftFractionalList.vue"
      ),
  },
  {
    path: "/:code",
    name: "LinkedPage",
    // route level code-splitting
    // this generates a separate chunk (DappsDetail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ArticleDetail" */ "../views/LinkedPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
